import React from "react"
import { Link, graphql } from "gatsby"
import Seo from "../components/seo"
import Layout from "../components/layout"
import ReactMarkdown from 'react-markdown'
const ThankYou = (props) => (
    <Layout>
        <div className="bg-white drop-shadow-xl border-t-7 md:px-8 px-4 mt-8 pb-6">
          <ReactMarkdown
          children={props?.data?.markdownRemark?.frontmatter?.content}
          components={{
            p: ({ children }) => {
              return (
                <p className="text-2xl text-center text-gray-900 font-light max-w-full my-5 ">
                  {children}
                </p>
              )
            },
            h2: ({ children }) => {
              return (
                <h2 className="text-3xl text-gray-600 leading-7 uppercase font-light title pb-5 mt-7 text-center mb-8">
                  {children}
                </h2>
              )
            },
            h3: ({ children }) => {
              return (
                <h3 className='md:text-2xl text-xl text-gray-600 uppercase  mt-5 font-medium title pb-5 mt-7 text-center mb-10 '>
                  {children}
                </h3>
              )
            },
            h4: ({ children }) => {
              return (
                <h4 className='text-2xl font-bold font3 mb-4'>{children}</h4>
              )
            },
            ul: ({ children }) => {
              return (
                <ul className='md:mb-16 mb-6 pl-2 list-disc'>{children}</ul>
              )
            },
            li: ({ children }) => {
              return <li className='text-sm font-normal mb-3'>{children}</li>
            }
          }}
        />
        </div>
    </Layout>
)

export const Head = props => (
    <Seo
      title={props?.data?.markdownRemark?.frontmatter?.meta?.seoTitle}
      description={props?.data?.markdownRemark?.frontmatter?.meta?.seoDescription}
      path={props.location.pathname}
    />
  )

export const data = graphql`
  query {
    markdownRemark(frontmatter: { slug: { eq: "thank-you" } }) {
      frontmatter {
        content
        meta {
          seoTitle
          seoDescription
        }
      }
    }
  }
`
export default ThankYou